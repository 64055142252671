import React from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

import Layout from "../components/layout";
import SEO from "../components/seo";
import FeaturedHero from "./../components/FeaturedHero";
import ReadOnCTA from "./../components/ReadOnCTA";
import FeaturedContent from "./../components/FeaturedContent";
import IconTimeline from "../components/IconTimeline";

export const query = graphql`
  {
    allPrismicProcessPage {
      edges {
        node {
          uid
          data {
            meta_description
            meta_title
            canonical {
              uid
              type
            }
            description {
              text
            }
            intro_description {
              text
            }
            intro_title {
              text
            }
            read_on_item {
              item_icon
              item_link_title
              item_link {
                uid
              }
              item_content {
                html
              }
              item_title {
                text
              }
            }
            read_on_title {
              text
            }
            title {
              text
            }
            timeline {
              item_icon
              item_summary {
                text
              }
              item_title {
                text
              }
            }
          }
        }
      }
    }
  }
`;

const ProcessPage = (props) => {
  const { location } = props;

  const { uid, data } = props.data.allPrismicProcessPage.edges[0].node;

  const meta = {
    descripition: data.meta_description,
    title: data.meta_title,
    uid: data.canonical.uid,
    pageType: data.canonical.type,
  };

  return (
    <Layout location={location}>
      <SEO meta={meta} description={meta.descripition} title={meta.title} uid={meta.uid} pageType={meta.pageType} />
      <div className="process page">
        <FeaturedHero
          title={data.title.text}
          description={data.description.text}
          label="Process"
          type="process"
        />
        <FeaturedContent
          label={data.intro_title.text}
          medium={data.intro_description.text}
        />
        <IconTimeline items={data.timeline} />
        <ReadOnCTA title={data.read_on_title} items={data.read_on_item} />
      </div>
    </Layout>
  );
};

ProcessPage.query = query;

export default withPreview(ProcessPage);
