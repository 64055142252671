import React from "react";
import "./IconTimeline.scss";
import { Waypoint } from "react-waypoint";

const ICONS = {
  brain: require("./../assets/svg/process-icon1.svg"),
  light: require("./../assets/svg/process-icon2.svg"),
  image: require("./../assets/svg/process-icon3.svg"),
  video: require("./../assets/svg/process-icon4.svg"),
  upload: require("./../assets/svg/process-icon5.svg"),
};

const IconTimelineItem = ({ item, index }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };
  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        key={`tli_${index}`}
        className={`icon-timeline__item waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <div className="icon-timeline__item-inner">
          <img
            className="icon-timeline__icon"
            src={ICONS[item.item_icon]}
            alt=""
          />
          <div className="icon-timeline__content">
            <h4 className="heading heading--l text--extrabold">
              {item.item_title.text}
            </h4>
            <p>{item.item_summary.text}</p>
          </div>
        </div>
      </div>
    </Waypoint>
  );
};

const IconTimeline = (props) => {
  const { items } = props;

  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`icon-timeline waypoint ${active ? "waypoint-active" : ""}`}
      >
        <div className="container">
          <div className="icon-timeline__inner">
            <div className="icon-timeline__background">
              <img src={require("./../assets/svg/timeline.svg")} alt="" />
            </div>
            <div className="icon-timeline__items">
              {items.map((item, index) => {
                return <IconTimelineItem item={item} index={index} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </Waypoint>
  );
};

export default IconTimeline;
